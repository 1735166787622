<template>
  <div>
    <span
      class="link mb-2"
      @click="back"
    > Назад </span>
    <b-row class="mt-2">
      <b-button
        v-if="icloudInfo && icloudInfo.status == 0"
        variant="outline-danger"
        class="btn-tour-skip w-100"
      >
        <span class="align-middle">
          <feather-icon icon="LockIcon" />
          Bloklangan</span>
      </b-button>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="6">
        <b-card
          v-if="icloudInfo"
          class="earnings-card"
        >
          <p>
            <b>FIO</b>:
            <span class="font-weight-bold">
              {{ [icloudInfo.lastname, icloudInfo.firstname].join(" ") }}</span>
          </p>
          <p><b>iCloud Email</b>: {{ icloudInfo.mail }}</p>
          <p><b>Telefoni</b>: +998{{ icloudInfo.phone }}</p>
          <p>
            <b>Yaratilgan sana</b>: {{ dateDotFormat(icloudInfo.created_at) }}
          </p>
          <p v-can="'iClouds.store'">
            <b>Icloud Parol</b>:
            <span v-if="showPassword"> {{ icloudInfo.icloud_password }}</span>
            <feather-icon
              v-if="!showPassword"
              icon="EyeIcon"
              class="m-2"
              @click="showPassword = true"
            />
            <feather-icon
              v-if="showPassword"
              icon="EyeOffIcon"
              class="m-2"
              @click="showPassword = false"
            />
          </p>
          <p v-can="'iClouds.store'">
            <b>Email Parol</b>:
            <span v-if="showPassword">
              {{
                icloudInfo.mail_password ? icloudInfo.mail_password : " - "
              }}</span>
            <feather-icon
              v-if="!showPassword"
              icon="EyeIcon"
              class="m-2"
              @click="showPassword = true"
            />
            <feather-icon
              v-if="showPassword"
              icon="EyeOffIcon"
              class="m-2"
              @click="showPassword = false"
            />
          </p>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card class="earnings-card">
          <div class="d-flex justify-content-between align-middle">
            <h3 class="mb-2">
              Biriktirilgan shartnoma ma'lumotlari
            </h3>
            <b-button
              v-if="
                icloudInfo &&
                  icloudInfo.contract_id &&
                  icloudInfo.contract_product_id &&
                  icloudInfo.contract
              "
              variant="outline-danger"
              class="float-right"
              size="small"
              @click="openModal('detach')"
            >
              <!-- <feather-icon icon="SendIcon" /> -->
              Icloudni Uzish
            </b-button>
          </div>
          <div
            v-if="
              icloudInfo &&
                icloudInfo.contract_id &&
                icloudInfo.contract_product_id &&
                icloudInfo.contract
            "
          >
            <p v-if="icloudInfo.contract.client">
              <b>Biriktirilgan mijoz</b>:
              <span
                class="link"
                @click="
                  $router.push({
                    name: 'payment-show',
                    params: { id: icloudInfo.contract.id },
                  })
                "
              >
                {{
                  [
                    icloudInfo.contract.client.lastname,
                    icloudInfo.contract.client.firstname,
                    icloudInfo.contract.client.middlename,
                  ].join(" ")
                }}
                ({{
                  icloudInfo.contract.client.doc_series +
                    icloudInfo.contract.client.doc_number +
                    " / " +
                    icloudInfo.contract.client.pin
                }})
              </span>
            </p>
            <p>
              <b>Mijoz telefoni</b>: +998{{ icloudInfo.contract.client.phone }};
              Yaqin qarindoshlari raqamlari: +998{{
                icloudInfo.contract.near_phone1
              }}, +998{{ icloudInfo.contract.near_phone2 }}
            </p>
            <p v-if="icloudInfo.contract">
              <b>Shartnoma summasi</b>:
              {{ icloudInfo.contract.credit_amount | formatPrice }} so'm
            </p>
            <p v-if="icloudInfo.contract_product">
              <b>Sotib olingan mahsulot</b>:
              {{ icloudInfo.contract_product.category.name_uz }}({{
                icloudInfo.contract_product.barcode
              }})
            </p>
            <p v-if="icloudInfo.contract.contract_pdf_url">
              <b>Shartnoma</b>:
              <a
                :href="storageUrlG + icloudInfo.contract.contract_pdf_url"
                target="_blank"
                class="link"
              >
                <feather-icon icon="PaperclipIcon" />Shartnoma</a>
            </p>
            <p>
              <b>Yaratilgan sana</b>:
              {{ dateDotFormat(icloudInfo.contract.created_at) }}
            </p>
          </div>
          <div v-else>
            <span class="text-muted">iCloud biriktirilmagan</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="6">
        <b-card class="earnings-card">
          <h3>Signallar</h3>
          <b-button
            variant="outline-success"
            class="btn-tour-skip"
            @click="openModal('signal')"
          >
            <span class="align-middle">
              <feather-icon icon="PlusIcon" />
              Signal qo'shish</span>
          </b-button>
          <hr>
          <div
            v-if="icloudInfo && icloudInfo.signals && icloudInfo.signals.length"
          >
            <p
              v-for="(item, index) in icloudInfo.signals"
              :key="index"
            >
              <b>Signal yuborildi</b>: {{ formatDateYmd(item.created_at) }},
              <b>Izoh</b>: {{ item.comment }} ({{ item.created_user.name }})
            </p>
          </div>
          <div v-else>
            Signal yuborilmagan
          </div>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card class="earnings-card">
          <h3>Bloklashlar</h3>
          <b-button
            variant="outline-danger"
            class="btn-tour-skip"
            @click="openModal('block')"
          >
            <span class="align-middle">
              <feather-icon
                :icon="icloudInfo.status == 1 ? 'LockIcon' : 'UnlockIcon'"
              />
              {{ icloudInfo.status == 1 ? "Bloklash" : "Blokdan chiqarish" }}
            </span>
          </b-button>
          <hr>
          <div
            v-if="icloudInfo && icloudInfo.blocks && icloudInfo.blocks.length"
          >
            <p
              v-for="(item, index) in icloudInfo.blocks"
              :key="index"
            >
              <b>{{ item.type == 0 ? "Bloklandi" : "Blokdan chiqarildi" }}</b>: {{ formatDateYmd(item.created_at) }}, <b>Izoh</b>:
              {{ item.comment }} ({{ item.created_user.name }})
            </p>
          </div>
          <div v-else>
            Hali bloklanmagan
          </div>
        </b-card>
      </b-col>
    </b-row>
    <div
      v-if="icloudInfo.contract_products && icloudInfo.contract_products.length"
      class="w-100"
    >
      <b-card class="w-100 earnings-card">
        <h3>Biriktirish tarixi</h3>

        <table class="table table-hover table-responsive w-100">
          <thead>
            <tr>
              <th>№</th>
              <th>Shartnoma</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(contract, index) in icloudInfo.contract_products">
              <tr
                :key="index"
              >
                <td>{{ index + 1 }}</td>
                <td
                  class="w-100"
                >
                  <p><b>Shartnoma: </b>

                    <span
                      class="link"
                      @click="
                        $router.push({
                          name: 'payment-show',
                          params: { id: contract.contract_id },
                        })
                      "
                    >Shartnoma</span>
                  </p>
                  <p><b>Mahsulot: </b>{{ contract.category.name_uz }}</p>
                  {{ contract.name }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>

      </b-card>
    </div>
    <b-modal
      v-model="signalModal"
      size="md"
      centered
      hide-footer
      :title="modalTitle"
      no-close-on-backdrop
      @close="signalModal = false"
      @cancel="signalModal = false"
    >
      <p
        v-if="clickFor == 'detach'"
        class="font-wight-bold"
      >
        iCloudni uzish orqali, ilgari biriktirilgan foydalanuvchi icloudi
        uziladi
      </p>
      <b-form
        v-else
        class="auth-login-form mt-2"
      >
        <b-form-group
          label="Izoh"
          label-for="tag"
        >
          <b-form-textarea
            id="body"
            v-model="comment"
            class="form-control"
            placeholder="Комментарий"
            rows="3"
          />
        </b-form-group>
      </b-form>
      <b-button
        variant="primary"
        class="float-right"
        size="small"
        @click="addAction"
      >
        <feather-icon
          v-if="clickFor != 'detach'"
          :icon="clickFor == 'signal' ? 'SendIcon' : 'LockIcon'"
        />
        {{
          clickFor == "signal"
            ? "Yuborish"
            : clickFor == "detach"
              ? "Uzish"
              : icloudInfo.status == 1
                ? "Bloklash"
                : "Blokdan chiqarish"
        }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { showToast } from '@/utils/toast'

export default {
  data() {
    return {
      icloudInfo: null,
      comment: '',
      signalModal: false,
      clickFor: 'signal',
      showPassword: false,
      fields: [
        {
          key: 'number',
          label: '№',
        },
        {
          key: 'date',
          label: 'Дата',
        },
        {
          key: 'amount',
          label: 'Сумма',
        },
      ],
    }
  },
  computed: {
    modalTitle() {
      switch (this.clickFor) {
        case 'signal':
          return 'Signal yuborish'
        case 'detach':
          return 'ICloudni uzish'

        default:
          return this.icloudInfo.status == 1 ? 'Bloklash' : 'Blokdan chiqarish'
      }
    },
  },
  mounted() {
    this.getAction()
  },
  methods: {
    ...mapActions({
      getItem: 'resource/getICloud',
      detachICloud: 'resource/detachICloud',
      addSignalICloud: 'resource/addSignalICloud',
      addBlockICloud: 'resource/addBlockICloud',
    }),
    getAction() {
      this.getItem({
        id: this.$route.params.id,
        relations:
          'contract.client|contractProduct.category|signals.createdUser|blocks.createdUser|contractProducts.category',
      }).then(res => {
        this.icloudInfo = res.data
      })
    },
    addAction() {
      this.method().then(res => {
        this.signalModal = false
        this.getAction()
      })
    },
    openModal(type) {
      this.clickFor = type
      this.signalModal = true
    },
    detachICloudAction() {
      this.detachICloud({ icloud_id: this.$route.params.id }).then(res => {
        if (res.success) {
          showToast('success', this.$t('Muvaffaqiyatli Uzildi'))
          this.getAction()
        }
      })
    },
    method() {
      if (this.clickFor == 'signal') {
        return this.addSignalICloud({
          id: this.$route.params.id,
          comment: this.comment,
        })
      }
      if (this.clickFor == 'detach') {
        return this.detachICloud({ icloud_id: this.$route.params.id })
      }
      return this.addBlockICloud({
        id: this.$route.params.id,
        type: this.icloudInfo.status == 1 ? 0 : 1,
        comment: this.comment,
      })
    },
  },
}
</script>

<style></style>
